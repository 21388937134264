import React, {useState, useEffect} from "react"
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import Helmet from "react-helmet"
import styled from 'styled-components'
import {BiChevronDown, BiChevronUp} from 'react-icons/bi'
import {AiFillCopy, AiOutlineCopy} from 'react-icons/ai'
import Beer from '../images/buymeabeer.png'


const MessageSpan = styled.div`
  position: fixed;
  left: ${props => `${props.xCoord}px`};
  top: ${props => `${props.yCoord}px`};
`

const GradientText = styled.span`
  background: -webkit-linear-gradient(50deg, #EC4899, #1E3A8A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Nav = (props) => {
  return (
    <div className="rounded-md p-1 w-100 md:w-1/4">
      <p className="text-xm text-gray-400 mb-5">Hover over the theme elements, click to open the sub-navigation and copy the code.</p>
      <ul>
        {props.data.map((e, i) => {
          return <Subnav setCurrentComponent={props.setCurrentComponent} 
                    key={i} data={e} />
        })}
      </ul>
    </div>
  )
}

const HoverIcon = (props) => {
  const [hover, setHover] = useState(false);
  // const [clicked, setClicked] = useState(false);

  return (
    <span className="ml-auto" 
      // onMouseDown={() => setClicked(true)}
      onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)}>
      {hover ? <AiFillCopy className="ml-auto" 
      onClickCapture={(e) => props.copyClicked(e)} /> : <AiOutlineCopy />}
    </span>
  )
}

const Message = (props) => {

  // console.log(props.coordinates);

  return (
    <MessageSpan xCoord={props.coordinates[0]}
                 yCoord={props.coordinates[1]} 
                 className='bg-gray-50 rounded-md p-2'>
      <p>Ein kleiner Test</p>
    </MessageSpan>
  )
}


const Subnav = (props) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [copyVisible, setCopyVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [modalCoordinates, setModalCoordinates] = useState(null);

  const classesForLi = `cursor-pointer text-lg md:text-xl hover:bg-gray-200 rounded pl-2 md:pl-4 ${props.data.sublevel ? "py-1 md:py-2 font-semibold" : "py-0 md:py-1"} flex items-center`;

  const copyClicked = (e) => {
    e.stopPropagation();

    // Copy only the code without the theme() wrapper
    navigator.clipboard.writeText(props.data.code.replace(/theme\(/g, '').replace(/\)$/g, ''));

    // setModalCoordinates(e.currentTarget.getBoundingClientRect());
    let svgImage = e.currentTarget.getBoundingClientRect();
    setModalCoordinates([svgImage.y, svgImage.x]);

    // console.log(svgImage);

    setMessageVisible(!messageVisible);

    setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessageVisible(false)
    }, 700)
  }

  const onMouseOverFunction = () => {
    props.setCurrentComponent(props.data);
    setCopyVisible(!copyVisible);
  }

  const onMouseOutFunction = () => {
    props.setCurrentComponent(null)
    setCopyVisible(!copyVisible);
  }

  return (
    <>
      {/* {messageVisible ? <Message coordinates={modalCoordinates} /> : null} */}
      <li className={classesForLi}
          onMouseEnter={() => onMouseOverFunction()}
          onMouseLeave={() => onMouseOutFunction()}
          onClick={() => setMenuOpen(!menuOpen)}>{props.data.name} 
          {props.data.sublevel ? menuOpen ? <BiChevronUp /> : <BiChevronDown /> : null} {copyVisible ? 
          <HoverIcon copyClicked={copyClicked} /> : null}</li>
      
      {/* Unordered List */}
      {props.data.sublevel ? <ul className={menuOpen ? "block pl-2 md:pl-4" : "hidden pl-2 md:pl-4"}>
        {props.data.sublevel.map((e, i) => {
          return <Subnav setCurrentComponent={props.setCurrentComponent} key={i} data={e} />
        })}
        
      </ul> : null }
    </>
  )
}

const Viz = (props) => {
  return (
    <div className="w-full shadow mb-5 mt-10 md:mt-0">
      {props.currentComponent ? <GatsbyImage image={props.currentComponent.image.childImageSharp.gatsbyImageData} 
        alt={props.currentComponent.name} className="w-full" /> : null}
    </div>
  )
}

const Code = (props) => {
  return (
    <>
      {props.currentComponent ? 
      <div className="bg-white p-4 text-sm md:text-xl shadow rounded">
        <p className="font-mono">{props.currentComponent ? props.currentComponent.code : ""}
          {/* <code className="break-words w-full">{props.currentComponent ? props.currentComponent.code : ""}</code> */}
        </p>
      </div> : null }
    </>

  )
}

const ThemeApp = (props) => {

  const themeData = props.data.theme.nodes[0].elements;
  const [currentComponent, setCurrentComponent] = useState(null);

  return (
    <Shell>
      <Helmet>
        <title>A complete guide to theme</title>
      </Helmet>

      <Container>
        <div className='mx-auto h-100 order-1 md:order-2 mb-20 md:mb-0 mt-10'>

          <div className='flex flex-col md:flex-row justify-between'>
            <div className='md:w-2/3' id='info'>
              <h1 className='text-xl md:text-3xl font-bold mb-5'>A complete guide on how to modify the ggplot2 <GradientText>theme</GradientText></h1>
              <p className='text-base md:text-lg text-gray-700 md:leading-relaxed'>You can use the ggplot2 theme to change the appearance of your visualization. However, it's hard to know all the elements by heart. Use this app as a quick reference to create your own theme. Just navigate through the elements, click to open the sub-navigation and copy and paste each argument straight into our code.</p>
            </div>
            <div className='mt-5 md:mt-0 flex'>
              <a href="https://www.buymeacoffee.com/ggplot2tor" rel="noreferrer" target="_blank" className='w-32 md:w-40'><img className='rounded' src={Beer} /></a>
            </div>
          </div>
        </div>

        <div className="my-12 w-full flex flex-col md:flex-row min-h-screen">
          <Nav data={themeData} setCurrentComponent={setCurrentComponent} /> 

          <div className="w-100 md:w-3/4 md:ml-10 sticky top-20 self-start">
            <Viz currentComponent={currentComponent} />
            <Code currentComponent={currentComponent} />
          </div>
          
        </div>
      </Container>
     

    </Shell>
  )
}

export default ThemeApp


export const query = graphql`
  query {
    theme: allYaml(filter: {name: {eq: "theme"}}) {
      nodes {
        id
        name
        elements {
          name
          image {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.8)
            }
          }
          code
          sublevel {
            code
            image {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.8)
              }
            }
            name 
            sublevel {
              code
              image {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.8)
                }
              }
              name
            }
          }
        }
      }
    }
  }
`